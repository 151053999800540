<template>
	<syfol-landing api_url="home" :common="common"></syfol-landing>
	<youtube-content></youtube-content>
	<syfol-works></syfol-works>
	<syfol-content api_url="home-content"></syfol-content>
	<syfol-simple-feature></syfol-simple-feature>
</template>

<script>
import Landing from "@/components/Landing.vue";
import YoutubeContent from "@/components/home/YoutubeContent.vue";
import SyfolWorks from "@/components/home/SyfolWorks.vue";
import SyfolContent from "@/components/home/SyfolContent.vue";
import SyfolSimpleFeature from "@/components/home/SyfolSimpleFeature.vue";
import { handleSlideAnimation } from '@/utils/animation.js';

export default {
  name: 'HomeView',
  props: ['common'],
  components: {
    'syfol-landing': Landing,
    'youtube-content': YoutubeContent,
    'syfol-works': SyfolWorks,
    'syfol-content': SyfolContent,
    'syfol-simple-feature': SyfolSimpleFeature,
  },
  mounted() {
    setTimeout(()=>{handleSlideAnimation(true)},0) // Wait until complete html render
  }
}
</script>