<template>
  <section v-for="(content, i) in contents" :key="content.id" @scroll="isScrolledIntoView" class="syfol-content">
    <div :class="'content-wrapper ' + getContentWrapperClass(content, i)">
      <div class="accordion-wrapper">
        <!-- alter content based on props  -->
        <div :class="'media scroll-animation ' + ((alter_content ? !(i % 2 == 0) : (i % 2 == 0)) ? '' : 'animate-left')">
          <img :src="content.cover_image" alt="" />
        </div>
        <div class="accordion" v-if="hasAccordion(content)">
          <div class="content" :class="{'active' : accordion_active == 1}">
            <div class="title" @click="accordion_active = accordion_active == 1 ? 0 : 1">
              <h3> {{content.accordion_1_title}} </h3>
              <i :class="`fa-solid fa-angles-${accordion_active == 1 ? 'down' : 'right'}`"></i>
            </div>
            <div class="body">
              <p> {{content.accordion_1_description}} </p>
            </div>
          </div>
          <div class="content" :class="{'active' : accordion_active == 2}">
            <div class="title" @click="accordion_active = accordion_active == 2 ? 0 : 2">
              <h3> {{content.accordion_2_title}} </h3>
              <i :class="`fa-solid fa-angles-${accordion_active == 2 ? 'down' : 'right'}`"></i>
            </div>
            <div class="body">
              <p> {{content.accordion_2_description}} </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="short-text" :class="content.view_mode == 1 ? 'text-center' : 'text-left'">{{ content.short_text }}</div>
        <h3 :class="content.view_mode == 1 ? 'text-center' : 'text-left'">{{ content.title }}</h3>
        <p :class="content.view_mode == 1 ? 'text-center' : 'text-left'" v-html="content.description"></p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    contents: {},
    accordion_active: 0,
  }),
  props: {
    api_url: '',
    alter_content: false
  },
  computed: {
    contents() {
      return this.convertTranslation(this.contents)
    }
  },
  mounted() {
    this.$api(this.api_url).then((res) => {
      this.contents = res;
    });
  },
  methods: {
    hasAccordion(content) {
      return content.accordion_1_title || content.accordion_2_title
    },
    getContentWrapperClass(content, index) {
      return (content.view_mode == 1 || this.hasAccordion(content) ? 'flex-column-reverse' : ((this.alter_content ? !(index % 2 == 0) : (index % 2 == 0)) ? 'flex-row-reverse' : 'flex-row'))
    }
  }
};
</script>
<style scoped>
@import "@/assets/css/faq.css";
@import "@/assets/css/content.css";
</style>