import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import eventBus from '@/utils/eventBus'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/feature',
    name: 'feature',
    component: () => import('../views/FeatureView.vue')
  },
  {
    path: '/use-case',
    name: 'use-case',
    component: () => import('../views/UseCaseView.vue')
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/PriceView.vue')
  },
  {
    path: '/unsubscribed',
    name: 'unsubscribed',
    component: () => import('../views/Unsubscribed.vue')
  },
  {
    path: '/confirm_subscribed',
    name: 'confirmBubscribed',
    component: () => import('../components/SubscribeEmail.vue')
  },
  {
    path: '/:lang(en|no)',
    name: 'translation',
    beforeEnter: (to, from, next) => {
      localStorage.setItem('locale', to.params.lang)
      eventBus.$emit('change-translation', { locale: to.params.lang })
      next('/');
    }
  },
  // {
  //   path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
  //   redirect: '/'
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
