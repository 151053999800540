<template>
  <footer>
    <div class="try_now">
      <h1>{{ footer.card_text }}</h1>
      <div>
        <a class="button" target="_blank" :href="common.button_url"> {{ common.button_text }}</a>
      </div>
    </div>
    <div class="info">
      <div class="info-box">
        <div class="image">
          <!-- <img src="https://syfol.com/wp-content/uploads/2022/09/SYFol-Logo-white-1-1-300x81.png" alt=""> -->
          <img :src="footer.footer_logo" alt="" />
        </div>
        <div class="info-text" v-html="footer.description1"></div>
        <div class="icons">
          <a class="icon" :href="footer.youtube_url" target="_blank"><i class="fa-brands fa-youtube"></i></a>
        </div>
      </div>
      <div class="info-box" v-html="footer.description2"></div>
      <div class="info-box" v-html="footer.description3"></div>
      <div class="info-box">
        <h3>{{footer.newsletter}}</h3>
        <input type="email" v-model="newsletter.email" />
        <!-- <div class="radio-box">
          <div class="radio">
            <input type="radio" name="reason" id="en" v-model="newsletter.language" value="en">
            <label for="en">English</label>
          </div>
          <div class="radio">
            <input type="radio" name="reason" id="no" v-model="newsletter.language" value="no">
            <label for="no">Norwegian</label>
          </div>
        </div> -->
        <button :disabled="loading" :style="!loading ? '' :'background-color: grey;'" class="button button_hov" @click="subscribe">{{ footer.subscribe }}</button>
      </div>
    </div>
    <div class="main-footer">
      <ul>
        <li>
          <a :href="footer.bottom_link_1"> {{ footer.bottom_text_1 }} </a> <br>
          <a :href="footer.bottom_link_2"> {{ footer.bottom_text_2 }} </a>
        </li>
        <li>
          <a href="https://twillcms.com/" target="_blank">#MadeWithTwill - A Laravel CMS</a>
        </li>
        <li>
          <a :href="footer.bottom_link_3"> {{ footer.bottom_text_3 }} </a>
        </li>
      </ul>
    </div>
  </footer>
</template>
<script>
import Swal from 'sweetalert2/dist/sweetalert2.js';
export default {
  props: ["footer", "common"],
  data: () => ({
    newsletter: {
      language: localStorage.getItem('locale') == 'no' ? 'no' : 'en',
    },
    loading: false
  }),
  // computed: {
  //   newsletter() {
  //     return this.convertTranslation(this.newsletter)
  //   }
  // },
  methods: {
    subscribe: function () {
      if (this.newsletter.email) {
        this.loading = true;
        this.newsletter.language = localStorage.getItem('locale') == 'no' ? 'no' : 'en'
        this.$api("news-letter-subscribe", "POST", this.newsletter).then((res) => {
          let translation = res.translations.find(trans => trans.locale == this.newsletter.language);
          let message = res.created ? res.message
            : (res.exist ? res.message  
            : translation.invalid_email);
          this.$toast.open({
            message: `<span style="color: white;">${message}</span>`,
            type: res.invalid ? 'error' : (res.exist ? 'warning' :  'success'),
            position: 'top-right'
          });
          // Swal.fire('', message, res.invalid ? 'error' : (res.exist ? 'warning' :  'success'), );
          this.newsletter = res.invalid ? this.newsletter : {}
          this.loading = false;
        });
      } else {
        var message = localStorage.getItem('locale') == 'no' ? 'Vennligst skriv inn e-postadressen din.' : 'Please enter your email.'
        this.$toast.open({
            message: `<span style="color: white;">${message}</span>`,
            type: 'info',
            position: 'top-right',
        });
        // Swal.fire('', message, 'error');
      }
    },
  },
  watch: {
    // "locale" (newVal, oldVal) {
    //   if (oldVal !== newVal) {
    //     this.newsletter.language = newVal
    //   }
    // }
  }
};
</script>

<style>
@import "@/assets/css/footer.css";
.button_hov {
  cursor: pointer;
}
</style>