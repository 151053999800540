<template>
	<div class="content-area">
		<common-header v-if="view" :common="common"></common-header>
		<router-view :common="common"/>
	</div>
	<common-footer v-if="view" :footer="footer" :common="common" @alert="handleAlert"></common-footer>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import eventBus from './utils/eventBus';
export default {
	components: {
		'common-header': Header,
		'common-footer': Footer
	},
	data: () => ({
		common: {},
		footer: {},
		view: true,
	}),
	computed: {
		common() {
		  return this.convertTranslation(this.common)
		},
    footer() {
		  return this.convertTranslation(this.footer)
		}
	},
	mounted() {
		this.$api('common').then(res => {
			this.footer = res.footer;
			delete res.footer;
			this.common = res;
			this.$root.common = res;
      eventBus.$emit('change_title')
		})
    eventBus.$on('change_title', () => {
      this.setPageTitle()
    })
    eventBus.$on('newsletterMessage', (data) => {
      this.view = data;
    })
	},

  methods: {
    setPageTitle() {
      let common = this.convertTranslation(this.common)
      if (this.$route && this.$route.name) {
        let current_page = this.$route.name.replace('-', '_')
        let title_key = current_page + "_page_title"
        let title = common[title_key]
        if (title) {
          document.title = title
        } else {
          title = this.$route.name
          title = title.charAt(0).toUpperCase() + title.slice(1);
          title = title.replace('-', ' ') + ' - SYFol'
          document.title = title
        }
      }
    }
  },
	beforeDestroy () {
		eventBus.$off('newsletterMessage');
	}
}
</script>