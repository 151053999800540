<template>
  <section class="youtube-content">
    <h2>{{ youtube_content.title }}</h2>
    <p>{{ youtube_content.description }}</p>
    <div class="content-wrapper">
      <div class="video">
        <iframe width="560" height="315" v-if="youtube_content.youtube_link" :src="'https://www.youtube.com/embed/' + youtube_content.youtube_link.split('/').pop()" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div class="content change-bullet" v-html="youtube_content.content"></div>
    </div>
  </section>
</template>
<script>
import changeListPoint from "@/utils/change_list_point";
export default {
  data: () => ({
    youtube_content: {},
  }),
  computed: {
    youtube_content() {
      return this.convertTranslation(this.youtube_content)
    }
  },
  methods: {
    /**
     * run changeListPoint function after translation has changed
     */
    transCallBack() {
      changeListPoint();
    }
  },
  mounted() {
    this.$api("youtube-content").then((res) => {
      this.youtube_content = res;
      changeListPoint();
    });
  },
};
</script>
<style>
@import "@/assets/css/youtube_content.css";
</style>