import Swal from 'sweetalert2/dist/sweetalert2.js';

export default {
  install(app) {
    // Add the $api method to the globalProperties of the Vue app
    app.config.globalProperties.$api = (url, method = 'GET', body) => {
      return new Promise((resolve) => {
        let data = {
          method,
          headers: { "Content-Type": "application/json" }
        };

        if (method == 'POST') {
          data.body = JSON.stringify(body);
        }

        // Perform the API request using fetch
        fetch(process.env.API_URL + url, data)
          .then(response => {
            if (!response.ok) {
              Swal.fire({ title: 'API Faild', text: response.statusText, backdrop: true });
              return false;
            }
            return response.json();
          })
          .then(data => {
            // Process the returned data
            resolve(data.data);
          })
          .catch(error => {
            // Handle the error
            Swal.fire({ title: 'Error!', text: error.message, backdrop: true });
            console.error('Error:', error.message);
          });
      });
    };
  }
};
