/**
* Adds a checkmark icon to each list item within elements with the class "benefit" and "content".
* This function utilizes a setTimeout with a delay of 0 to allow for rendering before modifying the DOM.
*/
export default function (parent_class = 'change-bullet') {
 setTimeout(() => {
  let li = document.querySelectorAll(`.${parent_class} li`);
  Array.from(li).forEach(element => {
    let point = document.createElement("i");
    point.className = "fa-solid fa-circle-check";
    element.prepend(point);
  });
 }, 0);
}