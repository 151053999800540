<template>
  <div class="landing">
    <div class="content">
      <h2>{{ landing.title }}</h2>
      <p>{{ landing.description }}</p>
      <a :href="common && common.button_url ? common.button_url : '' " target="_blank" class="disco button">{{
        common && common.button_text ? common.button_text : ''
      }}</a>
    </div>
    <div class="image scroll-animation">
      <img class="" :src="landing.landing_image" alt="" />
    </div>
    <div class="circle gravity-reverse circle1"></div>
    <div class="circle gravity circle2"></div>
    <div class="circle gravity circle3" v-if="!hide_big_circle"></div>
    <div class="circle gravity-reverse circle4"></div>
  </div>
</template>
<script>
export default {
  data: () => ({
    landing: false,
  }),
  props: ["api_url", "hide_big_circle", "common"],
  computed: {
    landing() {
      return this.convertTranslation(this.landing)
    }
  },
  mounted() {
    this.$api(this.api_url).then((res) => {
      this.landing = res;
    });
  },
};
</script>

<style scoped>
@import '@/assets/css/landing.css'
</style>