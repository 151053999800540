<template>
  <section>
    <h2>{{ syfol_work.title }}</h2>
    <p>{{ syfol_work.description }}</p>
    <div class="content-wrapper">
      <div class="card" v-for="work in syfol_work.work" :key="work.id">
        <div class="step">{{ work.number }}</div>
        <div class="content">{{ work.title }}</div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    syfol_work: {},
  }),
  computed: {
    syfol_work() {
      return this.convertTranslation(this.syfol_work)
    }
  },
  mounted() {
    this.$api("syfol-works").then((res) => {
      this.syfol_work = res;
    });
  },
};
</script>