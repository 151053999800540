const animation = {
  slide_animation: null,
  init() {
    this.slide_animation = window.addEventListener('scroll', this.handleSlideAnimation)
  },
  handleSlideAnimation(first_time = false) {
    let target_elements = document.querySelectorAll('.scroll-animation')
    let scrolled_offset = first_time ? window.innerHeight : window.scrollY;
    Array.from(target_elements).forEach(element => {
      let element_position = element.getBoundingClientRect()
      if (scrolled_offset > element_position.top) {
        element.style.visibility = 'visible'
        element.classList.add(element.classList.contains('animate-left') ? 'slide-left' : 'slide-right')
      }
    })
  }
}

const handleSlideAnimation = animation.handleSlideAnimation
export { handleSlideAnimation }
export default animation;