import eventBus from "@/utils/eventBus";

export default {
  data: () => ({
    locale: 'en',
  }),
  mounted() {
    let locale = localStorage.getItem('locale')
    this.locale = locale || this.locale
    eventBus.$on('change-translation', payload => {
      if (this.locale !== payload.locale) {
        this.locale = payload.locale
        eventBus.$emit('change_title')
        localStorage.setItem('locale', payload.locale)
        if (typeof this.transCallBack === 'function') {
          this.transCallBack();
        }
      }
    })
    eventBus.$emit('change_title')
  },
  methods: {
    // Change translation and emit it.
    changeTranslation(locale) {
      eventBus.$emit('change-translation', { locale })
    },
    // Convert translation by locale
    convertTranslation(data) {
      if (typeof data === 'object' && Array.isArray(data)) {
        return data.map( x => this.convertTranslation(x));
      } else {
        for (const key in data) {
          if (typeof data[key] === 'object' && Array.isArray(data[key])) {
            data[key] = data[key].map(child => this.convertTranslation(child))
          }
        }
        // eslint-disable-next-line no-prototype-builtins
        return data && data.hasOwnProperty('translations') ? { ...data, ...data.translations.find(trans => trans.locale == this.locale) } : data
      }
    }
  }
}