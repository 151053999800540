<template>
  <nav>
    <div class="navbar">
      <div class="logo">
        <a  href="/">
          <img :src="common.logo" alt="" srcset="" />
        </a>
      </div>
      <div class="switch" @click="showMenu = !showMenu">
        <i class="fa-solid fa-bars"></i>
      </div>
    </div>
    <div :class="'menu ' + (!showMenu ? 'hide' : '')">
      <ul>
        <li>
          <router-link @click="showMenu = false" to="/"> {{ common.home }} </router-link>
        </li>
        <li>
          <router-link @click="showMenu = false" to="/feature"> {{ common.features }} </router-link>
        </li>
        <li>
          <router-link @click="showMenu = false" to="/use-case"> {{ common.use_cases }} </router-link>
        </li>
        <li>
          <router-link @click="showMenu = false" to="/pricing"> {{ common.pricing }} </router-link>
        </li>
        <li>
          <a v-if="locale == 'en'" @click="changeTranslation('no')">
            <img v-if="locale == 'en'" src="@/assets/Norwegian.png" style="width: 30px; height: 15px; padding: 0px 5px 0px 0px;"/>
          </a>
          <a v-if="locale == 'no'" @click="changeTranslation('en')">
            <img v-if="locale == 'no'" src="@/assets/English.jpg" style="width: 30px; height: 15px;  padding: 0px 5px 0px 0px;"/>
          </a>
        </li>
      </ul>
      <div>
        <a :href="common.button_url" target="_blank" class="button">{{ common.button_text }}</a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'header',
  data: () => ({
    showMenu: false,
  }),
  props: ["common"],
  methods: {
    changeTrans() {
      this.locale = "no";
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/header.css";
</style>