import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import translation from './mixin/translation'
import api from './utils/api'
import animation from './utils/animation'
import 'sweetalert2/dist/sweetalert2.css';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

window.onload = animation.init();

const app = createApp(App)
app.use(router)
app.use(api)
app.use(ToastPlugin);
app.mixin(translation)
app.mount('#app')