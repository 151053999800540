<template>
  <section>
    <h2>{{ syfol_feature.title }}</h2>
    <div class="content-wrapper">
      <div class="icon-card card" v-for="feature in syfol_feature.features" :key="feature.id">
        <div class="icon" v-html="feature.icon"></div>
        <div class="content">{{ feature.title }}</div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    syfol_feature: {},
  }),
  computed: {
    syfol_feature() {
      return this.convertTranslation(this.syfol_feature)
    }
  },
  mounted() {
    this.$api("home-feature").then((res) => {
      this.syfol_feature = res;
    });
  },
};
</script>
<style scoped>
section .content-wrapper {
  flex-wrap: wrap;
}
</style>